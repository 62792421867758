import { Commit } from 'vuex';
import { FETCH_POLES, RECEIVE_POLES } from './constants';
import getPoles from '@/api/pole/get-pole';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POLES]: async (context: Context) => {
    const powerTransformersListings = await getPoles();
    context.commit(RECEIVE_POLES, powerTransformersListings);
  },
};

export default actions;
