import { Commit } from 'vuex';
import { FETCH_CLIENTS, RECEIVE_CLIENTS } from './constants';
import getClients from '@/api/client/get-client';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_CLIENTS]: async (context: Context) => {
    const clientsListings = await getClients();
    context.commit(RECEIVE_CLIENTS, clientsListings);
  },
};

export default actions;
