import { Commit } from 'vuex';
import {
  FETCH_POWER_TRANSFORMER_POWERS,
  RECEIVE_POWER_TRANSFORMER_POWERS,
} from './constants';
import getPowerTransformerPowers from '@/api/power-transformer-power/getPowerTransformerPower';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POWER_TRANSFORMER_POWERS]: async (context: Context) => {
    const powerTransformerPowesListings = await getPowerTransformerPowers();
    context.commit(
      RECEIVE_POWER_TRANSFORMER_POWERS,
      powerTransformerPowesListings,
    );
  },
};

export default actions;
