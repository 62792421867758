import { Commit } from 'vuex';
import { FETCH_PLANS, RECEIVE_PLANS } from './constants';
import getPlans from '@/api/plan/getPlans';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_PLANS]: async (context: Context) => {
    const planListings = await getPlans();
    context.commit(RECEIVE_PLANS, planListings);
  },
};

export default actions;
