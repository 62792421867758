import { GET_POLE_TYPES } from './constants';
import { PoleTypeState } from './types';

const getters = {
  [GET_POLE_TYPES](state: PoleTypeState) {
    return state.poleTypes;
  },
};

export default getters;
