const routes = [
  {
    path: 'add-power-transformer',
    name: 'add-power-transformer',
    component: () =>
      import(
        '@/modules/inspection/modules/power-transformer/views/AddPowerTransformer.vue'
      ),
  },
];

export default routes;
