import { Role } from './role';

export class User {
  id!: string;
  createdAt!: Date;
  updatedAt!: Date;
  email!: string;
  password!: string;
  firstName!: string;
  lastName!: string;
  picture!: string;
  phoneNumber!: string;
  roleId!: string;
  role!: Role;
}
