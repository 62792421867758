import { Commit } from 'vuex';
import { FETCH_METER_BRANDS, RECEIVE_METER_BRANDS } from './constants';
import getMeterBrand from '@/api/meter-brand/getMeterBrands';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METER_BRANDS]: async (context: Context) => {
    const meterBrandsListings = await getMeterBrand();
    context.commit(RECEIVE_METER_BRANDS, meterBrandsListings);
  },
};

export default actions;
