import { Client } from '@/models/client';
import {
  DELETE_CLIENT,
  RECEIVE_CLIENTS,
  SET_CLIENT,
  UPDATE_CLIENT,
} from './constants';
import { ClientState } from './types';

const mutations = {
  [RECEIVE_CLIENTS](store: ClientState, clients: Client[]) {
    store.clients = clients;
  },
  [SET_CLIENT](store: ClientState, powerTransformer: Client) {
    store.clients.push(powerTransformer);
  },
  [UPDATE_CLIENT](store: ClientState, powerTransformer: Client) {
    const index = store.clients.findIndex(
      (findClient) => findClient.id === powerTransformer.id,
    );
    store.clients.splice(index, 1, powerTransformer);
  },
  [DELETE_CLIENT](store: ClientState, id: string) {
    const index = store.clients.findIndex((findClient) => findClient.id === id);
    store.clients.splice(index, 1);
  },
};

export default mutations;
