import { WireDistribution } from '@/models/wireDistribution';
import {
  DELETE_WIRE_DISTRIBUTION,
  RECEIVE_WIRE_DISTRIBUTIONS,
  SET_WIRE_DISTRIBUTION,
  UPDATE_WIRE_DISTRIBUTION,
} from './constants';
import { WireDistributionState } from './types';

const mutations = {
  [RECEIVE_WIRE_DISTRIBUTIONS](
    store: WireDistributionState,
    wireDistributions: WireDistribution[],
  ) {
    store.wireDistributions = wireDistributions;
  },
  [SET_WIRE_DISTRIBUTION](
    store: WireDistributionState,
    powerTransformerType: WireDistribution,
  ) {
    store.wireDistributions.push(powerTransformerType);
  },
  [UPDATE_WIRE_DISTRIBUTION](
    store: WireDistributionState,
    powerTransformerType: WireDistribution,
  ) {
    const index = store.wireDistributions.findIndex(
      (findWireDistribution) =>
        findWireDistribution.id === powerTransformerType.id,
    );
    store.wireDistributions.splice(index, 1, powerTransformerType);
  },
  [DELETE_WIRE_DISTRIBUTION](store: WireDistributionState, id: string) {
    const index = store.wireDistributions.findIndex(
      (findWireDistribution) => findWireDistribution.id === id,
    );
    store.wireDistributions.splice(index, 1);
  },
};

export default mutations;
