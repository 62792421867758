import { GET_APPLIANCES } from './constants';
import { ApplianceState } from './types';

const getters = {
  [GET_APPLIANCES](state: ApplianceState) {
    return state.appliances;
  },
};

export default getters;
