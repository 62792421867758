import { LOGGED_USER } from '@/store/auth/constants';

import { AuthState } from '@/store/auth/types';

const getters = {
  [LOGGED_USER](state: AuthState) {
    return state.user;
  },
};

export default getters;
