import { GET_SUB_ACTIVITIES } from './constants';
import { SubActivityState } from './types';

const getters = {
  [GET_SUB_ACTIVITIES](state: SubActivityState) {
    return state.subActivities;
  },
};

export default getters;
