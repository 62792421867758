import {
  DELETE_AVAILABILITY,
  RECEIVE_AVAILABILITIES,
  SET_AVAILABILITY,
  UPDATE_AVAILABILITY,
} from './constants';
import { AvailabilityState } from './types';
import { Availability } from '@/models/availability';

const mutations = {
  [RECEIVE_AVAILABILITIES](
    store: AvailabilityState,
    availabilities: Availability[],
  ) {
    store.availabilities = availabilities;
  },
  [SET_AVAILABILITY](store: AvailabilityState, availability: Availability) {
    store.availabilities.push(availability);
  },
  [UPDATE_AVAILABILITY](store: AvailabilityState, availability: Availability) {
    const index = store.availabilities.findIndex(
      (findAvailability) => findAvailability.id === availability.id,
    );
    store.availabilities.splice(index, 1, availability);
  },
  [DELETE_AVAILABILITY](store: AvailabilityState, id: string) {
    const index = store.availabilities.findIndex(
      (findAvailability) => findAvailability.id === id,
    );
    store.availabilities.splice(index, 1);
  },
};

export default mutations;
