import { Pole } from '@/models/pole';
import api from '..';

const getPoles = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Pole>(`${baseUrl}/poles`);

  return response.data;
};

export default getPoles;
