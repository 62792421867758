const routes = [
  {
    path: 'done-activities',
    name: 'done-activities',
    component: () =>
      import('@/modules/inspection/modules/activity/views/DoneActivity.vue'),
  },
  {
    path: 'activities/:id',
    name: 'activities',
    component: () =>
      import('@/modules/inspection/modules/activity/views/ActivityDetails.vue'),
    props: true,
  },
  // {
  //   path: 'activity-details/:id',
  //   name: 'activities',
  //   component: () =>
  //     import('@/modules/inspection/modules/activity/views/ActivityDetails.vue'),
  //   props: true,
  // },
];

export default routes;
