import { MeterBrand } from '@/models/meterBrand';
import api from '..';

const getMeterBrand = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<MeterBrand>(`${baseUrl}/meter-brands`);

  return response.data;
};

export default getMeterBrand;
