import { Commit } from 'vuex';
import { FETCH_METER_GROUPS, RECEIVE_METER_GROUPS } from './constants';
import getMeterGroups from '@/api/meter-group/getMeterGroups';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METER_GROUPS]: async (context: Context) => {
    const meterGroupsListings = await getMeterGroups();
    context.commit(RECEIVE_METER_GROUPS, meterGroupsListings);
  },
};

export default actions;
