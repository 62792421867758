import { PoleType } from '@/models/pole-type';
import api from '..';

const getPoleTypes = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<PoleType>(`${baseUrl}/pole-types`);

  return response.data;
};

export default getPoleTypes;
