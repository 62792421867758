import { GET_WIRE_DISTRIBUTIONS } from './constants';
import { WireDistributionState } from './types';

const getters = {
  [GET_WIRE_DISTRIBUTIONS](state: WireDistributionState) {
    return state.wireDistributions;
  },
};

export default getters;
