import { Commit } from 'vuex';
import { FETCH_SUB_ACTIVITIES, RECEIVE_SUB_ACTIVITIES } from './constants';
import getSubActivities from '@/api/sub-activity/get-sub-activities';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_SUB_ACTIVITIES]: async (context: Context) => {
    const subActivityListings = await getSubActivities();
    context.commit(RECEIVE_SUB_ACTIVITIES, subActivityListings);
  },
};

export default actions;
