import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { AuthState } from './types';

export const keyAuth: InjectionKey<Store<AuthState>> = Symbol();

export default {
  state,
  mutations,
  actions,
  getters,
};
