import { Commit } from 'vuex';
import { FETCH_METER_MODELS, RECEIVE_METER_MODELS } from './constants';
import getMeterModels from '@/api/meter-model/getMeterModels';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METER_MODELS]: async (context: Context) => {
    const meterModelsListings = await getMeterModels();
    context.commit(RECEIVE_METER_MODELS, meterModelsListings);
  },
};

export default actions;
