import { Commit } from 'vuex';
import {
  FETCH_WIRE_DISTRIBUTIONS,
  RECEIVE_WIRE_DISTRIBUTIONS,
} from './constants';
import getWireDistributions from '@/api/wire-distribution/getWireDistribution';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_WIRE_DISTRIBUTIONS]: async (context: Context) => {
    const wireDistributionsListings = await getWireDistributions();
    context.commit(RECEIVE_WIRE_DISTRIBUTIONS, wireDistributionsListings);
  },
};

export default actions;
