import { ResidencyType } from '@/models/residencyType';
import api from '..';

const getResidencyTypes = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<ResidencyType>(`${baseUrl}/residency-types`);

  return response.data;
};

export default getResidencyTypes;
