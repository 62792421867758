import powerTransformerRoutes from '@/modules/inspection/modules/power-transformer/router';
import poloeRoutes from '@/modules/inspection/modules/pole/router';
import dataCollectionRoutes from '@/modules/inspection/modules/data-collection/router';
import activityRoutes from '@/modules/inspection/modules/activity/router';

const routes = [
  {
    path: '/app',
    name: 'Layout',
    // redirect: '/app/add-power-transformer',
    component: () => import('@/modules/inspection/views/home/index.vue'),
    meta: {
      hide: true,
    },
    children: [
      ...powerTransformerRoutes,
      ...poloeRoutes,
      ...dataCollectionRoutes,
      ...activityRoutes,
    ],
  },
];

export default routes;
