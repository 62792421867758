export const RECEIVE_POWER_TRANSFORMER_CONNECTIONS =
  'RECEIVE_POWER_TRANSFORMER_CONNECTIONS';
export const GET_POWER_TRANSFORMER_CONNECTIONS =
  'GET_POWER_TRANSFORMER_CONNECTIONS';
export const SET_POWER_TRANSFORMER_CONNECTION =
  'SET_POWER_TRANSFORMER_CONNECTION';
export const UPDATE_POWER_TRANSFORMER_CONNECTION =
  'UPDATE_POWER_TRANSFORMER_CONNECTION';
export const DELETE_POWER_TRANSFORMER_CONNECTION =
  'DELETE_POWER_TRANSFORMER_CONNECTION';
export const FETCH_POWER_TRANSFORMER_CONNECTIONS =
  'FETCH_POWER_TRANSFORMER_CONNECTIONS';
