import { Zone } from '@/models/zone';
import { DELETE_ZONE, RECEIVE_ZONES, SET_ZONE, UPDATE_ZONE } from './constants';
import { ZoneState } from './types';

const mutations = {
  [RECEIVE_ZONES](store: ZoneState, zones: Zone[]) {
    store.zones = zones;
  },
  [SET_ZONE](store: ZoneState, zone: Zone) {
    store.zones.push(zone);
  },
  [UPDATE_ZONE](store: ZoneState, zone: Zone) {
    const index = store.zones.findIndex((findZone) => findZone.id === zone.id);
    store.zones.splice(index, 1, zone);
  },
  [DELETE_ZONE](store: ZoneState, id: string) {
    const index = store.zones.findIndex((findZone) => findZone.id === id);
    store.zones.splice(index, 1);
  },
};

export default mutations;
