import { Plan } from '@/models/plan';
import { DELETE_PLAN, RECEIVE_PLANS, SET_PLAN, UPDATE_PLAN } from './constants';
import { PlanState } from './types';

const mutations = {
  [RECEIVE_PLANS](store: PlanState, plans: Plan[]) {
    store.plans = plans;
  },
  [SET_PLAN](store: PlanState, plan: Plan) {
    store.plans.push(plan);
  },
  [UPDATE_PLAN](store: PlanState, plan: Plan) {
    const index = store.plans.findIndex((findPlan) => findPlan.id === plan.id);
    store.plans.splice(index, 1, plan);
  },
  [DELETE_PLAN](store: PlanState, id: string) {
    const index = store.plans.findIndex((plan) => plan.id === id);
    store.plans.splice(index, 1);
  },
};

export default mutations;
