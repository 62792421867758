import {
  DELETE_SUB_ACTIVITY,
  RECEIVE_SUB_ACTIVITIES,
  SET_SUB_ACTIVITY,
  UPDATE_SUB_ACTIVITY,
} from './constants';
import { SubActivityState } from './types';
import { SubActivity } from '@/models/subActivity';

const mutations = {
  [RECEIVE_SUB_ACTIVITIES](
    store: SubActivityState,
    subActivities: SubActivity[],
  ) {
    store.subActivities = subActivities;
  },
  [SET_SUB_ACTIVITY](store: SubActivityState, subActivity: SubActivity) {
    store.subActivities.push(subActivity);
  },
  [UPDATE_SUB_ACTIVITY](store: SubActivityState, subActivity: SubActivity) {
    const index = store.subActivities.findIndex(
      (findSubActivity) => findSubActivity.id === subActivity.id,
    );
    store.subActivities.splice(index, 1, subActivity);
  },
  [DELETE_SUB_ACTIVITY](store: SubActivityState, id: string) {
    const index = store.subActivities.findIndex(
      (findSubActivity) => findSubActivity.id === id,
    );
    store.subActivities.splice(index, 1);
  },
};

export default mutations;
