import { GET_METER_MODELS } from './constants';
import { MeterModelState } from './types';

const getters = {
  [GET_METER_MODELS](state: MeterModelState) {
    return state.meterModels;
  },
};

export default getters;
