import { GET_POWER_TRANSFORMER_POWERS } from './constants';
import { PowerTransformerPowerState } from './types';

const getters = {
  [GET_POWER_TRANSFORMER_POWERS](state: PowerTransformerPowerState) {
    return state.powerTransformerPowers;
  },
};

export default getters;
