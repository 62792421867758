import api from '..';
import { Meter } from '@/models/meter';

const getMeters = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Meter>(`${baseUrl}/meters`);

  return response.data;
};

export default getMeters;
