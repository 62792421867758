import { GET_METER_GROUPS } from './constants';
import { MeterGroupState } from './types';

const getters = {
  [GET_METER_GROUPS](state: MeterGroupState) {
    return state.meterGroups;
  },
};

export default getters;
