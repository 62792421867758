import { GET_METER_BRANDS } from './constants';
import { MeterBrandState } from './types';

const getters = {
  [GET_METER_BRANDS](state: MeterBrandState) {
    return state.meterBrands;
  },
};

export default getters;
