import { GET_METER_TYPES } from './constants';
import { MeterTypeState } from './types';

const getters = {
  [GET_METER_TYPES](state: MeterTypeState) {
    return state.meterTypes;
  },
};

export default getters;
