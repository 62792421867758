import { GET_POWER_TRANSFORMER_TYPES } from './constants';
import { PowerTransformerTypeState } from './types';

const getters = {
  [GET_POWER_TRANSFORMER_TYPES](state: PowerTransformerTypeState) {
    return state.powerTransformerTypes;
  },
};

export default getters;
