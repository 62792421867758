import api from '..';
import { Appliance } from '@/models/appliance';

const getAppliances = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Appliance>(`${baseUrl}/appliances`);

  return response.data;
};

export default getAppliances;
