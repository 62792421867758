import { GET_METER_LOCATIONS } from './constants';
import { MeterLocationState } from './types';

const getters = {
  [GET_METER_LOCATIONS](state: MeterLocationState) {
    return state.meterLocations;
  },
};

export default getters;
