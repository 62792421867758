import { Commit } from 'vuex';
import { FETCH_METER_LOCATIONS, RECEIVE_METER_LOCATIONS } from './constants';
import getMeterLocations from '@/api/meter-location/getMeterLocations';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METER_LOCATIONS]: async (context: Context) => {
    const meterLocationsListings = await getMeterLocations();
    context.commit(RECEIVE_METER_LOCATIONS, meterLocationsListings);
  },
};

export default actions;
