import { Client } from '@/models/client';
import api from '..';

const getClients = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Client>(`${baseUrl}/clients`);

  return response.data;
};

export default getClients;
