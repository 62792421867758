import { Commit } from 'vuex';
import { FETCH_RESIDENCY_TYPES, RECEIVE_RESIDENCY_TYPES } from './constants';
import getResidencyTypes from '@/api/resindency-type/getResidencyTypes';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_RESIDENCY_TYPES]: async (context: Context) => {
    const residencyTypesListings = await getResidencyTypes();
    context.commit(RECEIVE_RESIDENCY_TYPES, residencyTypesListings);
  },
};

export default actions;
