import { GET_AVAILABILITIES } from './constants';
import { AvailabilityState } from './types';

const getters = {
  [GET_AVAILABILITIES](state: AvailabilityState) {
    return state.availabilities;
  },
};

export default getters;
