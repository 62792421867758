import { GET_PLANS } from './constants';
import { PlanState } from './types';

const getters = {
  [GET_PLANS](state: PlanState) {
    return state.plans;
  },
};

export default getters;
