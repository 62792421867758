import { PowerTransformer } from '@/models/powerTransformer';
import { GET_POWER_TRANSFORMERS } from './constants';
import { PowerTransformerState } from './types';

const removeDuplicates = (array: PowerTransformer[]) => {
  return array.filter(
    (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
  );
};

const getters = {
  [GET_POWER_TRANSFORMERS](state: PowerTransformerState) {
    return removeDuplicates(state.powerTransformers);
  },
};

export default getters;
