import { PowerTransformerPower } from '@/models/power-transformer-power';
import {
  DELETE_POWER_TRANSFORMER_POWER,
  RECEIVE_POWER_TRANSFORMER_POWERS,
  SET_POWER_TRANSFORMER_POWER,
  UPDATE_POWER_TRANSFORMER_POWER,
} from './constants';
import { PowerTransformerPowerState } from './types';

const mutations = {
  [RECEIVE_POWER_TRANSFORMER_POWERS](
    store: PowerTransformerPowerState,
    powerTransformerPowers: PowerTransformerPower[],
  ) {
    store.powerTransformerPowers = powerTransformerPowers;
  },
  [SET_POWER_TRANSFORMER_POWER](
    store: PowerTransformerPowerState,
    powerTransformerPower: PowerTransformerPower,
  ) {
    store.powerTransformerPowers.push(powerTransformerPower);
  },
  [UPDATE_POWER_TRANSFORMER_POWER](
    store: PowerTransformerPowerState,
    powerTransformerPower: PowerTransformerPower,
  ) {
    const index = store.powerTransformerPowers.findIndex(
      (findPowerTransformerPower) =>
        findPowerTransformerPower.id === powerTransformerPower.id,
    );
    store.powerTransformerPowers.splice(index, 1, powerTransformerPower);
  },
  [DELETE_POWER_TRANSFORMER_POWER](
    store: PowerTransformerPowerState,
    id: string,
  ) {
    const index = store.powerTransformerPowers.findIndex(
      (findPowerTransformerPower) => findPowerTransformerPower.id === id,
    );
    store.powerTransformerPowers.splice(index, 1);
  },
};

export default mutations;
