import { GET_CLIENTS } from './constants';
import { ClientState } from './types';

const getters = {
  [GET_CLIENTS](state: ClientState) {
    return state.clients;
  },
};

export default getters;
