import { User } from '@/models/user';
import api from '..';

const getLoggedUser = async () => {
  try {
    const baseUrl = process.env.VUE_APP_API_URL;
    const response = await api.get<User>(`${baseUrl}/me`);

    return response.data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export default getLoggedUser;
