import { MeterModel } from '@/models/meterModel';
import {
  DELETE_METER_MODEL,
  RECEIVE_METER_MODELS,
  SET_METER_MODEL,
  UPDATE_METER_MODEL,
} from './constants';
import { MeterModelState } from './types';

const mutations = {
  [RECEIVE_METER_MODELS](store: MeterModelState, meterModels: MeterModel[]) {
    store.meterModels = meterModels;
  },
  [SET_METER_MODEL](store: MeterModelState, meterModel: MeterModel) {
    store.meterModels.push(meterModel);
  },
  [UPDATE_METER_MODEL](store: MeterModelState, meterModel: MeterModel) {
    const index = store.meterModels.findIndex(
      (findMeterModel) => findMeterModel.id === meterModel.id,
    );
    store.meterModels.splice(index, 1, meterModel);
  },
  [DELETE_METER_MODEL](store: MeterModelState, id: string) {
    const index = store.meterModels.findIndex(
      (findMeterModel) => findMeterModel.id === id,
    );
    store.meterModels.splice(index, 1);
  },
};

export default mutations;
