const routes = [
  {
    path: 'data-collection',
    name: 'data-collection',
    component: () =>
      import(
        '@/modules/inspection/modules/data-collection/views/CollectDataForm.vue'
      ),
  },
];

export default routes;
