import { MeterLocation } from '@/models/meterLocation';
import api from '..';

const getMeterLocations = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<MeterLocation>(`${baseUrl}/meter-locations`);

  return response.data;
};

export default getMeterLocations;
