import {
  DELETE_METER_BRAND,
  RECEIVE_METER_BRANDS,
  SET_METER_BRAND,
  UPDATE_METER_BRAND,
} from './constants';
import { MeterBrandState } from './types';
import { MeterBrand } from '@/models/meterBrand';

const mutations = {
  [RECEIVE_METER_BRANDS](store: MeterBrandState, meterBrands: MeterBrand[]) {
    store.meterBrands = meterBrands;
  },
  [SET_METER_BRAND](store: MeterBrandState, meterBrand: MeterBrand) {
    store.meterBrands.push(meterBrand);
  },
  [UPDATE_METER_BRAND](store: MeterBrandState, meterBrand: MeterBrand) {
    const index = store.meterBrands.findIndex(
      (findMeterBrand) => findMeterBrand.id === meterBrand.id,
    );
    store.meterBrands.splice(index, 1, meterBrand);
  },
  [DELETE_METER_BRAND](store: MeterBrandState, id: string) {
    const index = store.meterBrands.findIndex(
      (findMeterBrand) => findMeterBrand.id === id,
    );
    store.meterBrands.splice(index, 1);
  },
};

export default mutations;
