import { PowerTransformerPower } from '@/models/power-transformer-power';
import api from '..';

const getPowerTransformerPowers = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<PowerTransformerPower>(
    `${baseUrl}/transformer-station-powers`,
  );

  return response.data;
};

export default getPowerTransformerPowers;
