import { ResidencyType } from '@/models/residencyType';
import {
  DELETE_RESIDENCY_TYPE,
  RECEIVE_RESIDENCY_TYPES,
  SET_RESIDENCY_TYPE,
  UPDATE_RESIDENCY_TYPE,
} from './constants';
import { ResidencyTypeState } from './types';

const mutations = {
  [RECEIVE_RESIDENCY_TYPES](
    store: ResidencyTypeState,
    residencyTypes: ResidencyType[],
  ) {
    store.residencyTypes = residencyTypes;
  },
  [SET_RESIDENCY_TYPE](
    store: ResidencyTypeState,
    residencyType: ResidencyType,
  ) {
    store.residencyTypes.push(residencyType);
  },
  [UPDATE_RESIDENCY_TYPE](
    store: ResidencyTypeState,
    residencyType: ResidencyType,
  ) {
    const index = store.residencyTypes.findIndex(
      (findResidencyType) => findResidencyType.id === residencyType.id,
    );
    store.residencyTypes.splice(index, 1, residencyType);
  },
  [DELETE_RESIDENCY_TYPE](store: ResidencyTypeState, id: string) {
    const index = store.residencyTypes.findIndex(
      (findResidencyType) => findResidencyType.id === id,
    );
    store.residencyTypes.splice(index, 1);
  },
};

export default mutations;
