import { Plan } from '@/models/plan';
import axios from 'axios';

const getPlans = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await axios.get<Plan>(`${baseUrl}/plans`);

  return response.data;
};

export default getPlans;
