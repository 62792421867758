import { PowerTransformerConnection } from '@/models/power-transformer-connection';
import {
  DELETE_POWER_TRANSFORMER_CONNECTION,
  RECEIVE_POWER_TRANSFORMER_CONNECTIONS,
  SET_POWER_TRANSFORMER_CONNECTION,
  UPDATE_POWER_TRANSFORMER_CONNECTION,
} from './constants';
import { PowerTransformerConnectionState } from './types';

const mutations = {
  [RECEIVE_POWER_TRANSFORMER_CONNECTIONS](
    store: PowerTransformerConnectionState,
    powerTransformerConnections: PowerTransformerConnection[],
  ) {
    store.powerTransformerConnections = powerTransformerConnections;
  },
  [SET_POWER_TRANSFORMER_CONNECTION](
    store: PowerTransformerConnectionState,
    powerTransformerConnection: PowerTransformerConnection,
  ) {
    store.powerTransformerConnections.push(powerTransformerConnection);
  },
  [UPDATE_POWER_TRANSFORMER_CONNECTION](
    store: PowerTransformerConnectionState,
    powerTransformerConnection: PowerTransformerConnection,
  ) {
    const index = store.powerTransformerConnections.findIndex(
      (findPowerTransformerConnection) =>
        findPowerTransformerConnection.id === powerTransformerConnection.id,
    );
    store.powerTransformerConnections.splice(
      index,
      1,
      powerTransformerConnection,
    );
  },
  [DELETE_POWER_TRANSFORMER_CONNECTION](
    store: PowerTransformerConnectionState,
    id: string,
  ) {
    const index = store.powerTransformerConnections.findIndex(
      (findPowerTransformerConnection) =>
        findPowerTransformerConnection.id === id,
    );
    store.powerTransformerConnections.splice(index, 1);
  },
};

export default mutations;
