import { Commit } from 'vuex';
import { FETCH_METERS, RECEIVE_METERS } from './constants';
import getMeters from '@/api/meter/getMetes';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METERS]: async (context: Context) => {
    const metersListings = await getMeters();
    context.commit(RECEIVE_METERS, metersListings);
  },
};

export default actions;
