import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store, { key } from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import i18n from './plugins/i18n';
import VueGoogleMaps from '@fawmi/vue-google-maps';

loadFonts();

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(Toast, {
    toastClassName: 'dashcode-toast',
    bodyClassName: 'dashcode-toast-body',
  })
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAR7m5Qjqenec7HafhdJjoJxKW2_XgXHIM',
      language: 'pt',
      libraries: 'places',
    },
    autobindAllEvents: true,
  })
  .use(vuetify);

// app.config.compilerOptions.isCustomElement = (tag) =>
//   tag.startsWith('capacitor-');

app.mount('#app');
