import { MeterLocation } from '@/models/meterLocation';
import {
  DELETE_METER_LOCATION,
  RECEIVE_METER_LOCATIONS,
  SET_METER_LOCATION,
  UPDATE_METER_LOCATION,
} from './constants';
import { MeterLocationState } from './types';

const mutations = {
  [RECEIVE_METER_LOCATIONS](
    store: MeterLocationState,
    meterLocations: MeterLocation[],
  ) {
    store.meterLocations = meterLocations;
  },
  [SET_METER_LOCATION](
    store: MeterLocationState,
    meterLocation: MeterLocation,
  ) {
    store.meterLocations.push(meterLocation);
  },
  [UPDATE_METER_LOCATION](
    store: MeterLocationState,
    meterLocation: MeterLocation,
  ) {
    const index = store.meterLocations.findIndex(
      (findMeterLocation) => findMeterLocation.id === meterLocation.id,
    );
    store.meterLocations.splice(index, 1, meterLocation);
  },
  [DELETE_METER_LOCATION](store: MeterLocationState, id: string) {
    const index = store.meterLocations.findIndex(
      (findMeterLocation) => findMeterLocation.id === id,
    );
    store.meterLocations.splice(index, 1);
  },
};

export default mutations;
