import { Pole } from '@/models/pole';
import { DELETE_POLE, RECEIVE_POLES, SET_POLE, UPDATE_POLE } from './constants';
import { PoleState } from './types';

const mutations = {
  [RECEIVE_POLES](store: PoleState, poles: Pole[]) {
    store.poles = poles;
  },
  [SET_POLE](store: PoleState, pole: Pole) {
    store.poles.push(pole);
  },
  [UPDATE_POLE](store: PoleState, pole: Pole) {
    const index = store.poles.findIndex((findPole) => findPole.id === pole.id);
    store.poles.splice(index, 1, pole);
  },
  [DELETE_POLE](store: PoleState, id: string) {
    const index = store.poles.findIndex((findPole) => findPole.id === id);
    store.poles.splice(index, 1);
  },
};

export default mutations;
