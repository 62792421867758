import { GET_POWER_TRANSFORMER_CONNECTIONS } from './constants';
import { PowerTransformerConnectionState } from './types';

const getters = {
  [GET_POWER_TRANSFORMER_CONNECTIONS](state: PowerTransformerConnectionState) {
    return state.powerTransformerConnections;
  },
};

export default getters;
