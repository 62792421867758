import { InjectionKey } from 'vue';
import { Store, createStore } from 'vuex';
import authModule from '@/store/auth';
import zoneModule from '@/store/admin/zone';
import powerTransformer from '@/store/admin/power-transformer';
import powerTransformerType from '@/store/admin/power-transformer-type';
import poleModule from '@/store/admin/pole';
import planModule from '@/store/admin/plan';
import residencyType from './admin/residency-type';
import wireDistribution from './admin/wire-distribution';
import meterType from './admin/meter-type';
import meterModel from './admin/meter-model';
import meterLocation from './admin/meter-location';
import meterBrand from './admin/meter-brand';
import availability from './admin/availability';
import meter from './admin/meter';
import applliance from './admin/appliance';
import subActivityModule from '@/store/admin/sub-activity';
import clientModule from '@/store/admin/client';
import powerTransformerConnectionModule from '@/store/admin/power-transformer-connection';
import powerTransformerPowerModule from '@/store/admin/power-transformer-power';
import poleTypeModule from '@/store/admin/pole-type';
import meterGroupModule from '@/store/admin/meter-group';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const key: InjectionKey<Store<any>> = Symbol();

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authModule,
    zoneModule,
    powerTransformer,
    powerTransformerType,
    residencyType,
    availability,
    poleModule,
    planModule,
    wireDistribution,
    meterType,
    meterModel,
    meterLocation,
    meterBrand,
    meter,
    applliance,
    subActivityModule,
    clientModule,
    powerTransformerConnectionModule,
    powerTransformerPowerModule,
    poleTypeModule,
    meterGroupModule,
  },
});
