import { Commit } from 'vuex';
import {
  FETCH_POWER_TRANSFORMER_CONNECTIONS,
  RECEIVE_POWER_TRANSFORMER_CONNECTIONS,
} from './constants';
import getPowerTransformerConnections from '@/api/power-transformer-connection/getPowerTransformerConnection';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POWER_TRANSFORMER_CONNECTIONS]: async (context: Context) => {
    const powerTransformerTypesListings =
      await getPowerTransformerConnections();
    context.commit(
      RECEIVE_POWER_TRANSFORMER_CONNECTIONS,
      powerTransformerTypesListings,
    );
  },
};

export default actions;
