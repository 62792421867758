import { GET_RESIDENCY_TYPES } from './constants';
import { ResidencyTypeState } from './types';

const getters = {
  [GET_RESIDENCY_TYPES](state: ResidencyTypeState) {
    return state.residencyTypes;
  },
};

export default getters;
