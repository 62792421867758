import { PowerTransformer } from '@/models/powerTransformer';
import api from '..';

const getPowerTransformers = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<PowerTransformer>(
    `${baseUrl}/transformer-stations`,
  );

  return response.data;
};

export default getPowerTransformers;
