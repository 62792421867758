import { PoleType } from '@/models/pole-type';
import {
  DELETE_POLE_TYPE,
  RECEIVE_POLE_TYPES,
  SET_POLE_TYPE,
  UPDATE_POLE_TYPE,
} from './constants';
import { PoleTypeState } from './types';

const mutations = {
  [RECEIVE_POLE_TYPES](store: PoleTypeState, poleTypes: PoleType[]) {
    store.poleTypes = poleTypes;
  },
  [SET_POLE_TYPE](store: PoleTypeState, poleType: PoleType) {
    store.poleTypes.push(poleType);
  },
  [UPDATE_POLE_TYPE](store: PoleTypeState, poleType: PoleType) {
    const index = store.poleTypes.findIndex(
      (findPoleType) => findPoleType.id === poleType.id,
    );
    store.poleTypes.splice(index, 1, poleType);
  },
  [DELETE_POLE_TYPE](store: PoleTypeState, id: string) {
    const index = store.poleTypes.findIndex(
      (findPoleType) => findPoleType.id === id,
    );
    store.poleTypes.splice(index, 1);
  },
};

export default mutations;
