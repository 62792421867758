import { Commit } from 'vuex';
import { FETCH_AVAILABILITIES, RECEIVE_AVAILABILITIES } from './constants';
import getAvailabilities from '@/api/availability/getAvailabilities';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_AVAILABILITIES]: async (context: Context) => {
    const availabilitiesListings = await getAvailabilities();
    context.commit(RECEIVE_AVAILABILITIES, availabilitiesListings);
  },
};

export default actions;
