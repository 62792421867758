import { User } from '@/models/user';
import { LOGIN_USER, LOGOUT_USER, FETCH_LOGGED_USER } from './constants';
import { AuthState } from './types';

const mutations = {
  [LOGIN_USER](state: AuthState) {
    state.isLoggedIn = true;
  },
  [LOGOUT_USER](state: AuthState) {
    state.isLoggedIn = false;
  },
  [FETCH_LOGGED_USER](state: AuthState, user: User) {
    state.user = user;
  },
};

export default mutations;
