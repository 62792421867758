import { Commit } from 'vuex';
import {
  FETCH_POWER_TRANSFORMER_TYPES,
  RECEIVE_POWER_TRANSFORMER_TYPES,
} from './constants';
import getPowerTransformerTypes from '@/api/power-transformer-type/getPowerTransformerTypes';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POWER_TRANSFORMER_TYPES]: async (context: Context) => {
    const powerTransformerTypesListings = await getPowerTransformerTypes();
    context.commit(
      RECEIVE_POWER_TRANSFORMER_TYPES,
      powerTransformerTypesListings,
    );
  },
};

export default actions;
