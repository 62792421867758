import { PowerTransformer } from '@/models/powerTransformer';
import {
  DELETE_POWER_TRANSFORMER,
  RECEIVE_POWER_TRANSFORMERS,
  SET_POWER_TRANSFORMER,
  UPDATE_POWER_TRANSFORMER,
} from './constants';
import { PowerTransformerState } from './types';

const mutations = {
  [RECEIVE_POWER_TRANSFORMERS](
    store: PowerTransformerState,
    powerTransformers: PowerTransformer[],
  ) {
    store.powerTransformers = powerTransformers;
  },
  [SET_POWER_TRANSFORMER](
    store: PowerTransformerState,
    powerTransformer: PowerTransformer,
  ) {
    store.powerTransformers.push(powerTransformer);
  },
  [UPDATE_POWER_TRANSFORMER](
    store: PowerTransformerState,
    powerTransformer: PowerTransformer,
  ) {
    const index = store.powerTransformers.findIndex(
      (findPowerTransformer) => findPowerTransformer.id === powerTransformer.id,
    );
    store.powerTransformers.splice(index, 1, powerTransformer);
  },
  [DELETE_POWER_TRANSFORMER](store: PowerTransformerState, id: string) {
    const index = store.powerTransformers.findIndex(
      (findPowerTransformer) => findPowerTransformer.id === id,
    );
    store.powerTransformers.splice(index, 1);
  },
};

export default mutations;
