const routes = [
  {
    path: 'add-poles',
    name: 'add-poles',
    component: () =>
      import('@/modules/inspection/modules/pole/views/AddPole.vue'),
  },
];

export default routes;
