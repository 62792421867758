import { PowerTransformerConnection } from '@/models/power-transformer-connection';
import api from '..';

const getPowerTransformerConnections = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<PowerTransformerConnection>(
    `${baseUrl}/transformer-station-connections`,
  );

  return response.data;
};

export default getPowerTransformerConnections;
