import { Commit } from 'vuex';
import { FETCH_APPLIANCES, RECEIVE_APPLIANCES } from './constants';
import getAppliances from '@/api/appliance/getAppliances';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_APPLIANCES]: async (context: Context) => {
    const appliancesListings = await getAppliances();
    context.commit(RECEIVE_APPLIANCES, appliancesListings);
  },
};

export default actions;
