import { MeterType } from '@/models/meterType';
import {
  DELETE_METER_TYPE,
  RECEIVE_METER_TYPES,
  SET_METER_TYPE,
  UPDATE_METER_TYPE,
} from './constants';
import { MeterTypeState } from './types';

const mutations = {
  [RECEIVE_METER_TYPES](store: MeterTypeState, meterTypes: MeterType[]) {
    store.meterTypes = meterTypes;
  },
  [SET_METER_TYPE](store: MeterTypeState, powerTransformerType: MeterType) {
    store.meterTypes.push(powerTransformerType);
  },
  [UPDATE_METER_TYPE](store: MeterTypeState, powerTransformerType: MeterType) {
    const index = store.meterTypes.findIndex(
      (findMeterType) => findMeterType.id === powerTransformerType.id,
    );
    store.meterTypes.splice(index, 1, powerTransformerType);
  },
  [DELETE_METER_TYPE](store: MeterTypeState, id: string) {
    const index = store.meterTypes.findIndex(
      (findMeterType) => findMeterType.id === id,
    );
    store.meterTypes.splice(index, 1);
  },
};

export default mutations;
