import { Meter } from '@/models/meter';
import {
  DELETE_METER,
  RECEIVE_METERS,
  SET_METER,
  UPDATE_METER,
} from './constants';
import { MeterState } from './types';

const mutations = {
  [RECEIVE_METERS](store: MeterState, meters: Meter[]) {
    store.meters = meters;
  },
  [SET_METER](store: MeterState, meter: Meter) {
    store.meters.push(meter);
  },
  [UPDATE_METER](store: MeterState, meter: Meter) {
    const index = store.meters.findIndex(
      (findMeter) => findMeter.id === meter.id,
    );
    store.meters.splice(index, 1, meter);
  },
  [DELETE_METER](store: MeterState, id: string) {
    const index = store.meters.findIndex((findMeter) => findMeter.id === id);
    store.meters.splice(index, 1);
  },
};

export default mutations;
