import { Commit } from 'vuex';
import { FETCH_POLE_TYPES, RECEIVE_POLE_TYPES } from './constants';
import getPoleTypes from '@/api/pole-type/getPoleTypes';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POLE_TYPES]: async (context: Context) => {
    const poleTypesListings = await getPoleTypes();
    context.commit(RECEIVE_POLE_TYPES, poleTypesListings);
  },
};

export default actions;
