import { Commit } from 'vuex';
import {
  FETCH_POWER_TRANSFORMERS,
  RECEIVE_POWER_TRANSFORMERS,
} from './constants';
import getPowerTransformers from '@/api/power-transformer/get-power-transformers';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_POWER_TRANSFORMERS]: async (context: Context) => {
    const powerTransformersListings = await getPowerTransformers();
    context.commit(RECEIVE_POWER_TRANSFORMERS, powerTransformersListings);
  },
};

export default actions;
