import { Commit } from 'vuex';
import { FETCH_ZONES, RECEIVE_ZONES } from './constants';
import getZones from '@/api/zone/getZones';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_ZONES]: async (context: Context) => {
    const planListings = await getZones();
    context.commit(RECEIVE_ZONES, planListings);
  },
};

export default actions;
