import { Commit } from 'vuex';

import getLoggedUser from '@/api/auth/getLoggedUser';
import { FETCH_LOGGED_USER } from './constants';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_LOGGED_USER]: async ({ commit }: Context) => {
    const user = await getLoggedUser();
    return commit('FETCH_LOGGED_USER', user);
  },
};

export default actions;
