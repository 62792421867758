import api from '..';
import { WireDistribution } from '@/models/wireDistribution';

const getWireDistributions = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<WireDistribution>(
    `${baseUrl}/wire-distributions`,
  );

  return response.data;
};

export default getWireDistributions;
