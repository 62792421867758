import { MeterGroup } from '@/models/meterGroup';
import api from '..';

const getMeterGroups = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<MeterGroup>(`${baseUrl}/meter-groups`);

  return response.data;
};

export default getMeterGroups;
