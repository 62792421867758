import { MeterModel } from '@/models/meterModel';
import api from '..';

const getMeterModels = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<MeterModel>(`${baseUrl}/meter-models`);

  return response.data;
};

export default getMeterModels;
