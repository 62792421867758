import { Commit } from 'vuex';
import { FETCH_METER_TYPES, RECEIVE_METER_TYPES } from './constants';
import getMeterTypes from '@/api/meter-type/getMeterTypes';

interface Context {
  commit: Commit;
}

const actions = {
  [FETCH_METER_TYPES]: async (context: Context) => {
    const meterTypesListings = await getMeterTypes();
    context.commit(RECEIVE_METER_TYPES, meterTypesListings);
  },
};

export default actions;
