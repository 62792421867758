import { GET_POLES } from './constants';
import { PoleState } from './types';

const getters = {
  [GET_POLES](state: PoleState) {
    return state.poles;
  },
};

export default getters;
