import { AUTH_TOKEN } from '@/constants';
import axios from 'axios';

const httpEndpoint =
  process.env.VUE_APP_API_URL ||
  'https://sgi-backend-demo-a33db0c4b25c.herokuapp.com/api';
// const httpEndpoint =
//   process.env.VUE_APP_API_URL || 'https://scgd-d245020d51e5.herokuapp.com/api';

const token = localStorage.getItem(AUTH_TOKEN);

// axios.defaults.baseURL = httpEndpoint;
// axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: httpEndpoint, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});
// api.defaults.withCredentials = true;

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      // If the token exists, add it to the Authorization header
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
