import { SubActivity } from '@/models/subActivity';
import api from '..';

const getSubActivities = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<SubActivity>(`${baseUrl}/sub-activities/my`);

  return response.data;
};

export default getSubActivities;
