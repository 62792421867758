import { PowerTransformerType } from '@/models/powerTransformerType';
import {
  DELETE_POWER_TRANSFORMER_TYPE,
  RECEIVE_POWER_TRANSFORMER_TYPES,
  SET_POWER_TRANSFORMER_TYPE,
  UPDATE_POWER_TRANSFORMER_TYPE,
} from './constants';
import { PowerTransformerTypeState } from './types';

const mutations = {
  [RECEIVE_POWER_TRANSFORMER_TYPES](
    store: PowerTransformerTypeState,
    powerTransformerTypes: PowerTransformerType[],
  ) {
    store.powerTransformerTypes = powerTransformerTypes;
  },
  [SET_POWER_TRANSFORMER_TYPE](
    store: PowerTransformerTypeState,
    powerTransformerType: PowerTransformerType,
  ) {
    store.powerTransformerTypes.push(powerTransformerType);
  },
  [UPDATE_POWER_TRANSFORMER_TYPE](
    store: PowerTransformerTypeState,
    powerTransformerType: PowerTransformerType,
  ) {
    const index = store.powerTransformerTypes.findIndex(
      (findPowerTransformerType) =>
        findPowerTransformerType.id === powerTransformerType.id,
    );
    store.powerTransformerTypes.splice(index, 1, powerTransformerType);
  },
  [DELETE_POWER_TRANSFORMER_TYPE](
    store: PowerTransformerTypeState,
    id: string,
  ) {
    const index = store.powerTransformerTypes.findIndex(
      (findPowerTransformerType) => findPowerTransformerType.id === id,
    );
    store.powerTransformerTypes.splice(index, 1);
  },
};

export default mutations;
