import { GET_ZONES } from './constants';
import { ZoneState } from './types';

const getters = {
  [GET_ZONES](state: ZoneState) {
    return state.zones;
  },
};

export default getters;
