import api from '..';
import { Availability } from '@/models/availability';

const getAvailabilities = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Availability>(`${baseUrl}/availabilities`);

  return response.data;
};

export default getAvailabilities;
