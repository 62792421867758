import { GET_METERS } from './constants';
import { MeterState } from './types';

const getters = {
  [GET_METERS](state: MeterState) {
    return state.meters;
  },
};

export default getters;
