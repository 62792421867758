import { PowerTransformerType } from '@/models/powerTransformerType';
import api from '..';

const getPowerTransformerTypes = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<PowerTransformerType>(
    `${baseUrl}/transformer-station-types`,
  );

  return response.data;
};

export default getPowerTransformerTypes;
