import {
  DELETE_APPLIANCE,
  RECEIVE_APPLIANCES,
  SET_APPLIANCE,
  UPDATE_APPLIANCE,
} from './constants';
import { ApplianceState } from './types';
import { Appliance } from '@/models/appliance';

const mutations = {
  [RECEIVE_APPLIANCES](store: ApplianceState, appliances: Appliance[]) {
    store.appliances = appliances;
  },
  [SET_APPLIANCE](store: ApplianceState, appliance: Appliance) {
    store.appliances.push(appliance);
  },
  [UPDATE_APPLIANCE](store: ApplianceState, appliance: Appliance) {
    const index = store.appliances.findIndex(
      (findAppliance) => findAppliance.id === appliance.id,
    );
    store.appliances.splice(index, 1, appliance);
  },
  [DELETE_APPLIANCE](store: ApplianceState, id: string) {
    const index = store.appliances.findIndex(
      (findAppliance) => findAppliance.id === id,
    );
    store.appliances.splice(index, 1);
  },
};

export default mutations;
