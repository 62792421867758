import { MeterGroup } from '@/models/meterGroup';
import {
  DELETE_METER_GROUP,
  RECEIVE_METER_GROUPS,
  SET_METER_GROUP,
  UPDATE_METER_GROUP,
} from './constants';
import { MeterGroupState } from './types';

const mutations = {
  [RECEIVE_METER_GROUPS](store: MeterGroupState, meterGroups: MeterGroup[]) {
    store.meterGroups = meterGroups;
  },
  [SET_METER_GROUP](store: MeterGroupState, powerTransformerGroup: MeterGroup) {
    store.meterGroups.push(powerTransformerGroup);
  },
  [UPDATE_METER_GROUP](
    store: MeterGroupState,
    powerTransformerGroup: MeterGroup,
  ) {
    const index = store.meterGroups.findIndex(
      (findMeterGroup) => findMeterGroup.id === powerTransformerGroup.id,
    );
    store.meterGroups.splice(index, 1, powerTransformerGroup);
  },
  [DELETE_METER_GROUP](store: MeterGroupState, id: string) {
    const index = store.meterGroups.findIndex(
      (findMeterGroup) => findMeterGroup.id === id,
    );
    store.meterGroups.splice(index, 1);
  },
};

export default mutations;
