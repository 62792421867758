import { User } from '@/models/user';
import { AuthState } from './types';

const state = (): AuthState => {
  return {
    isLoggedIn: false,
    user: new User(),
  };
};

export default state;
